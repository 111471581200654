<template>
  <div id="content-list">
      <div class="list-item"
        v-for="item,index in list" :key="index"
      >
        <router-link :to="`/hotnews/`+ index">
          <div class="pic">
            <img :src="item.pic">
          </div>
          <div class="txt">
            <h5>
              {{ item.title }}
            </h5>
            <p>
              {{ item.content }}
            </p>
            <i>
              {{item.date }}
            </i>
          </div>
        </router-link>
      </div> 
  </div>
</template>

<script>

export default {

  data() {
    return {
      list: []
    }
  },
  beforeMount() {
    let obj = this.$store.state.hotNewsList;
    for (let i = 0; i < obj.length; i++) {
      this.list.push( {
        title: obj[i].title,
        date: obj[i].date,
        content: !obj[i].content[0].txt[0] ? obj[i].content[1].txt[0] : obj[i].content[0].txt[0],
        pic: !obj[i].content[0].pic[0] ? !obj[i].content[1].pic[0] ? obj[i].content[2].pic[0] : obj[i].content[1].pic[0] : obj[i].content[0].pic[0]
      })

    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.clear {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div.list-item {
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  padding-left: 1.25rem;
  border-bottom: 1px solid #d9d9d9;
}
div.list-item > a {
  display: block;
  overflow: hidden;
  text-decoration: none;
  color: #000;
  outline: none;
}
div.list-item > a > div.pic {
  float: left;
  width: 26%;
  overflow: hidden;
}
div.list-item > a > div.pic > img {
  display: block;
  width: 100%;
  transition: 0.6s all;
}
div.list-item:hover > a > div.pic > img {
  transition: 0.6s all;
  transform: scale(1.1);
}
div.list-item > a > div.txt {
  margin-left: 30%; 
}
div.list-item > a > div.txt > h5 {
  transition: 0.6s all;
  font-size: 1.125rem;
  color: #333333;
  height: 1.25rem;
  line-height: 1.25rem;
  overflow: hidden;
  margin-bottom: 1.875rem;
  font-weight: bold;
}
div.list-item:hover > a > div.txt > h5 {
  color: #2784ff;
  transition: 0.6s all;
}
div.list-item > a > div.txt > p {
  font-size: 0.875rem;
  color: #666666;
  line-height: 1.125rem;
  overflow: hidden;
  margin-bottom: 0.875rem;
  text-align: justify;
	text-justify: distribute;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
}
div.list-item > a > div.txt > i {
  font-style: normal;
  font-size: 0.875rem;
  color: #999999;
  padding-left: 18px;
  background: url('../../../assets/timeic.png') no-repeat left center;
}
</style>